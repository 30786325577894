

@font-face {
    font-display: swap;
    font-style: normal;
    font-family: "Galano";
    src: url('../assets/fonts/GalanoGrotesqueRegular.woff2') format("woff2");
    font-weight: 400;
  }
  
  @font-face {
    font-display: swap;
    font-style: normal;
    font-family: "Galano ";
    src: url('../assets/fonts/GalanoGrotesqueMedium.woff2') format("woff2");
    font-weight: 500;
  
  }
  @font-face {
    font-display: swap;
    font-style: normal;
    font-family: "Galano";
    src: url('../assets/fonts/GalanoGrotesqueSemiBold.woff2') format("woff2");
    font-weight: 600;
  
  }
  
  @font-face {
    font-display: swap;
    font-style: normal;
    font-family: "Galano";
    src: url('../assets/fonts/GalanoGrotesqueBold.woff2') format("woff2");
    font-weight: 700;
  
  }
  html {
    scroll-behavior: smooth;
  }
    
  * {
    font-family:'Galano',sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
 
   
  }